import React from 'react';

const Copyright = () => {
    return (
        <div className="copyright">
            <p>© { ( new Date().getFullYear() ) } Tasman SNL Pty Ltd </p>
        </div>
    );
};

export default Copyright;
