import React from 'react';

const AboutContent = () => {
    return (
        <div id="about" className="block spacer p-top-xl">
            <div className="title">
                <h2>About Us</h2>
            </div>

            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <h5>Your best choice for photography and travel in Tasmania.</h5>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <p>Tasman Studio (Tasman SNL Pty Ltd) was founded by Shawn, Nick and Leo, who are passionate about travel and photography. <br/>
                    We love adventures by nature and we have been to almost every place in Tasmania. Our expertise enables us to provide high quality service and give you an unforgettable travel experience in Tasmania.   <br/> 
                     </p>
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <h5>About the founders</h5>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <p><b>Shawn</b>  <br/> 
                    Having studied Tourism in University of Tasmania, Shawn is specialised in travel planning and guiding. He also has many years experience in real estate, architecture and landscape photography. 
                    </p>
                    <p><b>Nick</b>  <br/> 
                    Nick is a professional wedding videographer who has worked in Sydney's market for 4 years. He's extensive use of cinematic techniques gives dramatic and dreamy wedding footage. He likes shooting travel videos as a hobby.
                    </p>
                    <p><b>Leo</b>  <br/> 
                    Leo is a Tasmania based photographer. He has been amazed by the nature of Tasmania especially the wildlife and beautiful landscape. Leo is also a southernlights (Aurora) chaser as the Aurora can be witnessed and captured in the far south of Tasmania.
                    </p>
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <h5>Our Equipment</h5>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <p>We use professional cameras and lenses for our photography and video works. Our cameras include Canon EOS R5, Fujifilm X-T4, Sony A7S III and so on which meets even the highest standards for image quality. We use high-end studio equipment like high-power strobes for our commercial works. <br/> 
                     </p>
                </div>
            </div>
        </div>
    );
};

export default AboutContent;
