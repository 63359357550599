import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitlePortfolioInside from '../blocks/page-title/PageTitlePortfolioInside';
import PortfolioProject from '../blocks/portfolio/PortfolioProject';
import PortfolioSlider from '../blocks/portfolio/PortfolioSlider';
import PortfolioPagination from '../blocks/portfolio/PortfolioPagination';

import ProjectListData from '../data/portfolio/projectListData';


import { useParams } from 'react-router';

const PortfolioInside = () => {
    document.body.classList.add( 'single' );
    document.body.classList.add( 'single-portfolio' );
    let { id } = useParams();
    const path = ProjectListData[id-1].projectDataPath;

    const projectData = require(`../data/portfolio/${path}/portfolioProjectData.json`);
    const projectDescriptionData = require(`../data/portfolio/${path}/portfolioProjectDescriptionData.json`);
    const projectSwiperData = require(`../data/portfolio/${path}/portfolioSwiperData.json`);

    console.log(projectDescriptionData.client);
    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>  {projectDescriptionData.client} | Tasman Studio </title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header />

            <main id="main" className="site-main">
                <div id="page-content">
                    <div className="content">
                        <div id="single">
                            <div className="single-content">
                                <PageTitlePortfolioInside data={projectDescriptionData}/>

                                <PortfolioProject data={projectData} descriptionData={projectDescriptionData} />

                                <PortfolioSlider swiperData={projectSwiperData} />

                                <PortfolioPagination projectId={id} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default PortfolioInside;
