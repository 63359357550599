import React from 'react';

const WhyUsContent = () => {
    return (
        <div id="about" className="block spacer p-top-xl">
            <div className="title">
                <h2>Why Choosing Us</h2>
            </div>

            <div className="row gutter-width-md with-pb-md">
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <h5>Born In Tasmania, Serve Everyone.</h5>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <p>Tasman SNL PTY LTD is genuinely Tasmanian. Founded in Tasmania by Tasmanian phogographers and Outdoor Lovers.  <br/> 
                     </p>
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <h5>Choosing Us For Photography</h5>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <p>
                        We are professional photographers and videographers who have years of experience in wedding, event, product, landscape and travel photography. We catch the light to make your property or products shine. We capture emotions to preserve your precious memory.
                    </p>
                    <p>
                        We are understanding and thoughtful. We listen to your requirements carefully while providing our professional insight. Your satisfaction is our first priority.
                    </p>
                    <p>
                        We use high-end gear for our commercial works. We have cameras including Sony A7S III, Canon EOS R5, Fujifilm XT4 and so on. For studio, real-estate and portrait shots, we use high-power studio strobes to bring out beautiful lighting and true-to-life colours. Combined with our skills, we produce high image quality photos and videos.
                    </p>
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <h5>Choosing Us For Travel </h5>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <p>We love the great nature of Tasmania. We have traveled almost every corners in Tasmania. We know Tasmania better than most others. Our experience enables us to make better plans for your vacation in Tasmania.
                     </p>
                     <p>We value service quality over anything else. We understand that people's preferences differs. So we provide exclusive and customised experience just for you. 
                     </p>
                     <p>We also photograph your journey. Tasmania is no ordinary place. With stunning and spectacular scenery around, you just need to immerse into it and we will capture photos that make you shine on your social media.
                     </p>
                </div>
            </div>
        </div>
    );
};

export default WhyUsContent;
