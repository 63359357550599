import React from 'react';

const PageTitleContacts = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">Contacts.</h1>
                </div>

                <div className="description-2">
                    <p>Your can contact us via WeChat, phone or email. Alternatively, you can leave us a message by filling the form below. </p>
                </div>
            </div>
        </section>
    );
};

export default PageTitleContacts;
