import React from 'react';
import ProjectListData from '../../data/portfolio/projectListData.json';

const PortfolioPagination = (props) => {
    const totalProject = ProjectListData.length;
    let hasNextProject = true;
    let hasPreviousProject = true

    let nextProjectTitle = "";
    let previousProjectTitle = "";

    if(Number(props.projectId) === Number(totalProject)){
        hasNextProject= false;
    }
    if(Number(props.projectId) === Number(1)){
        hasPreviousProject = false;
    }
    if (hasNextProject){
        const nextPath = ProjectListData[props.projectId].projectDataPath;
        const nextProjectDescriptionData = require(`../../data/portfolio/${nextPath}/portfolioProjectDescriptionData.json`);
        nextProjectTitle = nextProjectDescriptionData.subtitle;
    }
    
    if (hasPreviousProject){
        const previousPath = ProjectListData[props.projectId-2].projectDataPath;
        const previousProjectDescriptionData = require(`../../data/portfolio/${previousPath}/portfolioProjectDescriptionData.json`);
        previousProjectTitle = previousProjectDescriptionData.subtitle;
    }
    console.log(hasPreviousProject);
    console.log(hasNextProject);

    return (
        <div id="pagination" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="portfolio-pagination">
                    <div className="row justify-content-between gutter-width-md with-pb-lg">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6"  hidden={!hasPreviousProject}>
                            <a className="d-flex justify-content-start link previous-link" href={ process.env.PUBLIC_URL + "/portfolio-inside/" + (props.projectId-1)}>
                                <div className="d-flex align-items-center justify-content-start">
                                    <div className="left">
                                        <p><i className="fas fa-long-arrow-alt-left"></i></p>
                                    </div>

                                    <div className="right">
                                        <p className="link-text after">Previous project</p>
                                        <h4 className="link-title">{previousProjectTitle}</h4>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6"  hidden={!hasNextProject}>
                            <a className="d-flex justify-content-end link next-link" href={ process.env.PUBLIC_URL + "/portfolio-inside/" + (Number(props.projectId)+1)}>
                                <div className="d-flex align-items-center justify-content-end">
                                    <div className="left">
                                        <p className="link-text after">Next project</p>
                                        <h4 className="link-title">{nextProjectTitle}</h4>
                                    </div>

                                    <div className="right">
                                        <p><i className="fas fa-long-arrow-alt-right"></i></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioPagination;
