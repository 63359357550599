import React from 'react';

const PageTitleHome = () => {
    return (
        <section id="page-title" className="block">
            <div className="wrapper">
                <div className="page-title-content">
                    <div className="page-title-img">
                        <img src="assets/img/background/20998787.png" alt="Background" />
                    </div>

                    <div className="page-title-description">
                        <div className="title">
                            <h1 className="large">TASMAN STUDIO<br/>Travel And Filming</h1>
                        </div>

                        <div className="description">
                            <p>The No.1 choice for filming and traveling in Tasmania.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitleHome;
