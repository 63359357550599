import React from 'react';

const PageTitlePortfolio = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">Portfolio.</h1>
                </div>

                <div className="description-2">
                    <p>Check the photos from our past projects. Click to choose one project and see its detail. </p>
                </div>
            </div>
        </section>
    );
};

export default PageTitlePortfolio;
