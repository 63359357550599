import React from 'react';

const PageTitleAbout = () => {
    return (
        <section id="page-title">
            <div className="wrapper">
                <div className="title">
                    <h1 className="large">About.</h1>
                </div>

                <div className="description-2">
                    <p>Located in the beautiful Hobart, Tasmania, Tasman Studio provides professional travel and photography/videography services. <br/>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default PageTitleAbout;
